$white: #fff !default;
$gray-100: #f8f9fc !default;
$gray-200: #eaecf4 !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #3a3b45 !default;
$title-confirmed: #77707e;
$black: #000 !default;

$blue: #0084C3 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;

// Custom Colors
$silbeck-color: #0084C3;
$text-color: #666;

// Input
$input-border-color: #ccc;
$input-text-color: $text-color;
$input-height: 36px;
$input-border-radius: 2px;

// label
$label-color: #333333;

//link
$link-color: #0059B2;

$body-color: #555 !default;

$font-family-sans-serif: 'Roboto', sans-serif !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
$box-shadow-form-data: 0px 0px 1px #aab;
$box-shadow-focus: 0px 0px 1px rgb(156, 156, 156);
$box-shadow-pix: 0px 0px 1px #777;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: #eaeaea;

$topbar-base-height: 60px;
$sidebar-base-width: 15rem;


// Adjust column spacing for symmetry
$spacer: 1rem;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;

//Botao
$fundo-button-color: #71DC56;
$strong-color: #007fff;
$border-button-color: #47C527;
$color-button-reserva: #0059B2;

//Fundo topo
$background-topo: #FAFAFA;
$background-total: #EEEEEE;

//Bordas
$border-menu: #BBBBBB;
$border-menu-identificador: #d2d2d2;
$border-color-payment-security: transparent #4cad34 transparent transparent;
$border-error: 1px #f44336 solid;
$color-error: #f44336;
$border-percentage-pix: 1px solid #47C527;
$border-focus-select: 1px solid #888;
$border-input-default: 1px solid #bbb;
$border-input-focus: 1px solid rgb(156, 156, 156);
$border-form-data:  1px solid #ccc;

//Skeleton
$background-animation: #e4e4e4;
$animation: #f4f5f7;
$animation-100: #f0f0f0;

//fontSize
$font-size-20px: 20px;
