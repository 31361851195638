// Animation Utilities

// Grow In Animation

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animated--grow-in {
  animation-name: growIn;
  animation-duration: 200ms;
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

// Fade In Animation

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated--fade-in {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.loading {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  flex-direction: column;
  i {
    animation: rotation 1.5s linear infinite;
  }
}

.border-load::before {
  content: "\e86a";
  font-family: "Material Icons";
  font-weight: normal;
  position: absolute;
  right: 8px;
  font-size: 22px;
  transform: rotateZ(0);
  animation: rotation 1.5s linear infinite;
  color: #ccc;
}

.widget-load::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.widget-load::after {
  content: "\e86a";
  z-index: 1;
  font-family: "Material Icons";
  font-weight: normal;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 22px;
  width: 22px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  margin: auto;
  transform: rotateZ(0);
  animation: rotation 1.5s linear infinite;
  color: #aaa;
}

.show-loading {
  position: relative;
  pointer-events: none;
}
.show-loading::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: inherit;
}
.show-loading::after {
  content: "\e86a";
  z-index: 1;
  font-family: "Material Icons";
  font-weight: normal;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 22px;
  width: 22px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  margin: auto;
  transform: rotateZ(0);
  animation: rotation 1.5s linear infinite;
  color: #000;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.load-line {
  display: block;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 15px;
    animation: loadingLine 0.8s linear infinite;
    background-color: $green;
    
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: #{0.1s * $i};
      }
    }
  }

}
@keyframes loadingLine {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(25px, 0);
    opacity: 0.6;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.2;
  }
}

.load-spiner {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid red;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//Animation skeleton
@keyframes pulse {
  0% {
      background-color: $animation;
  }

  50% {
      background-color: $animation-100;
  }

  100% {
      background-color: $animation;
  }
}