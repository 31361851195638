// Variables (Overrides Default Bootstrap Variables)
@import "variables";

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap-grid.scss';

// Import Custom SB Admin 2 Mixins and Components
@import "mixins";
@import "header";
@import "global";
@import "footer";
@import "utilities/animation";
@import "utilities/text";
@import "utilities/progress";

