// Global component styles
// @include media-breakpoint-up(xs) { less than 576px }
// @include media-breakpoint-up(sm) { landscape phones, 576px and up }
// @include media-breakpoint-up(md) { Medium devices (tablets, 768px and up }
// @include media-breakpoint-up(lg) { Large devices (desktops, 992px and up }
// @include media-breakpoint-up(xl) { Extra large devices (large desktops, 1200px and up }

html {
  position: relative;
  min-height: 100%;
  outline: none ;
}

body {
  min-height: 100%;
  color: $body-color;
  font-family: $font-family-sans-serif;
  margin: 0;
  padding: 0;
}

#root{
  min-height: 100%;
}

#wait-skeleton{
  min-height: 100vh;
}

.box-menu{
  background-color: $background-animation;
  height: 200px;
  min-width: 400px;
  z-index: 0;
  @include media-breakpoint-up(lg){    
    width: 760px;
    height: 120px;
  }
}

.box-menu::before{
  position: absolute;
  content: "";
  height: 200px;
  min-width: 100%;
  @include media-breakpoint-up(lg){    
    width: 760px;
    height: 120px;
  }
  animation: pulse 2s infinite ease-in-out;
}

.box-logo{
  background-color: $background-animation;
  z-index: 1;
  @include media-breakpoint-up(lg){
    width: 100%;
    height: 120px;
  }
}
.box-logo::before{
  position: absolute;
  content: "";
  height: 120px;
  width: 100%;
  animation: pulse 2s infinite ease-in-out;
}
.data-form{
  min-height: calc(100vh - 141px);
}

.sidebar-skeleton{
  z-index: 5;  
  width: 100%;
  height: 260px;
  background-color: $background-animation;
}

.sidebar-skeleton::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  animation: pulse 2s infinite ease-in-out;
}

.input-skeleton{
  width: 100vw;
}

.input-skeleton-email{
  background-color: $background-animation;
  z-index: 2;
  height: 40px;
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 384px;
  }
}
.input-skeleton-email::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 384px;
  }
  animation: pulse 2s infinite ease-in-out;
}

.btn-skeleton{
  background-color: $background-animation;
  height: 50px;
  width: 384px;
  margin-top: 50%;
  z-index: 4;
  @include media-breakpoint-up(lg){
    width: 262px;
  }
}

.btn-skeleton::before{
  position: absolute;
  content: "";
  height: 50px;
  @include media-breakpoint-up(lg){
    width: 262px;
  }
  width: 384px;
  animation: pulse 2s infinite ease-in-out;
}

input, textarea {
  color: $label-color;
  font-family: $font-family-sans-serif;
  min-height: 40px;
}

select {
  border: $border-input-default;
  font-family: $font-family-sans-serif;
  min-height: 40px;
  &:focus{
    box-shadow: $box-shadow-focus;
    border: $border-input-focus;
  }
}

a {
  &,
  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: $link-color;
    cursor: pointer;
  }
}

.link {
  color: $link-color;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}

.link-support{
  color: $gray-700;
  text-decoration: none;
}

.link-support:hover{
  color: $gray-700;
  text-decoration: underline;
}


// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, 0.5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: $white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}

.material-icons {
  vertical-align: middle;
}

.page-title {
  font-size: 1.25rem;
  color: $text-color;
  font-weight: 500;
  margin: 0.5rem 0;
  text-align: center;
}

.text-color  {
  color: $text-color;
}

.icon-lg {
  font-size: 30px;
}

.icon-md {
  font-size: $font-size-20px;
}

.swal2-title {
  font-size: 1.5em;
}
.mi-flip-horizontal {
  transform: scaleX(-1);
}
.mi-flip-vertical {
  transform: scaleX(-1);
}



.btn {
  background-color: $fundo-button-color;
  border: 0;
  border-bottom: 4px solid $border-button-color;
  color: $white;
  font-size: $font-size-20px;
  height: 50px;
  outline: none;
  padding: 0;
  padding-top: 0.2rem;
  width: 100%; 
  &:hover {
    background-color: lighten($fundo-button-color, 10%);
    cursor: pointer;
    outline: none;
  }
  &:active {
    background-color: darken($fundo-button-color, 10%); 
    outline: none;
  }
  &:disabled{
    background-color: $border-menu;
    border-bottom: none;
  }
  strong {
    color: $strong-color;
  }
  span {
    font-size: 22px;
  }

i {
  width: 15px; 
  height: 24px;
}

}

.btn-cep {
  color: $text-color;
  position: absolute;
  left: 132px;
  bottom: 1px;
  height: 38px;
  width: 32px;
  padding: 0;
  border: 1px solid $border-menu-identificador;
  border-right: none;
  outline: none;
  cursor: pointer;
  @include media-breakpoint-up(lg){
    right: 26px;
  }
}

.try-again {
  width: 90vw;
  @include media-breakpoint-up(lg){
    width: 20vw;
    margin-top: 25%;
  }
}

li {
  list-style: none;
}

.order {
    width: 25px;
    height: 25px;
    background-color: $fundo-button-color;
    border-radius: 50%;
    text-decoration: none;
    display: inline-block;
    color: $white;
    line-height: 25px;
    position: relative;
    box-shadow: solid 0px 0px 1px 1px; 
    font-size: 15px;
}

.prime{
  position: relative;
  top: 23px;
}

ul.prime::before {
@include media-breakpoint-up(lg) {
    display: block;
    position: relative;
    content: "";
    height: 1px;
    width: 100%;
    border-bottom: 2px solid $border-menu;
    top: 17px;
    z-index: 0px;
    border-radius: 1px;
  }    
}
.item-menu {
  ul {
    padding: 0;
    text-align: center;
  }
    font-size: 12px;
    text-align: center;
    width: 101%;

  @include media-breakpoint-up(lg){
    background-color: $background-topo;
    border: 1px solid $border-menu-identificador;
    font-size: 21px;
    min-height: 120px;
    padding: 0px 35px;
    text-align: center;
    width: 100%;
    ul {
      margin: 10px -17px;
      padding: 0px;
    }
  }
}

select.input-default.payment-med {
  height: 30px;
  padding-bottom: 0;
  color: $label-color;
}

select.input-default.short {
  padding: 3px;
  color: $label-color;
}
.menu-listing {
  display: inline-block;
  cursor: pointer;
  outline: none;
   @include media-breakpoint-up(lg){
    text-decoration: none;
    margin-left: 30px;
    margin-right: 30px;
   }
}

option {
  margin: 5px 0px;
}

#space-item {
  margin: 0px 20px 0px 20px;
}

#space-item::after {
  display: block;
  content: "";
  position: relative;
  bottom: 38px;
  width: 54px;
  left: 34px;
  margin-left: 35px;
  border-bottom: 2px solid $border-menu;
  @include media-breakpoint-up(lg){
    display: none;
   }
}



#space-id::after {
  display: block;
  content: "";
  position: relative;
  left: 35px;
  bottom: 38px;
  width: 55px;
  margin-left: 26px;
  border-bottom: 2px solid $border-menu;
  @include media-breakpoint-up(lg){
    display: none;
   }
}

#final {
  background-color: $border-menu-identificador;
  color: $white;
}

#confirmacao {
  background-color: $border-menu-identificador;
  color: $white;
}

.menu-title {
  padding: 5px;
  @include media-breakpoint-up(lg){
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
  }
}

label  {
  font-size: 12px;
  @include media-breakpoint-up(lg){
    text-align: end;
  }
}

.text-category {
  font-weight: 500;
}

input{
  border: $border-input-default;
  height: 30px;
  outline: none;
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 390px;
    height: 30px;
  }
  &:focus{
    box-shadow: $box-shadow-focus;
    border: $border-input-focus;
  }
}

.hotel-identifier {
  background-color: $background-topo;
  border-bottom: 1px solid $input-border-color;
  min-height: 120px;
}

.img-hotel {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid $input-border-color;
  text-align: center;
  padding: 15px;
  display: flex;
  }

.picture {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  left: 8%;
  right: 0;
  margin: auto;
  }

.hotel-name{
  font-size: 18px;
  font-weight: 400;
  @include media-breakpoint-up(lg){
    font-size: 22px;
  }
}

.central {
  margin-top: 10px;
}

.link-central {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}


#close {
  position: absolute;
  top: 21px;
  right: 22px;
  cursor: pointer;
  @include media-breakpoint-up(lg){
    position: absolute;
  top: 10px;
  right: 32px;
  cursor: pointer;
  }
}

.mail{
  padding: 5px;
  font-size: $font-size-20px;
}

.titulo-modal {
  text-align: center;
}

.titulo-modal.nome {
  font-size: $font-size-20px;
}

.dados-modal{
  margin-left: 5px;
  @include media-breakpoint-up(lg){
    display: flex;
  justify-content: center;
  text-align: center;
  }
}


#identificacao {
  background-color: $fundo-button-color;
  color: $white;
}

.identificador-cartao {
  text-align: center;
  #img-cartao {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    #img-cartao {
      display: inline-block;
    }
  }
}

.payment-methods {
  border: 2px solid $gray-300;
  border-radius: 10px;
  cursor: pointer;
  font-size: 10px;
  min-height: 100%;
  min-width: 130px;
  outline: none;
  padding-top: 5px;
  text-align: center;
  span {
    width: 25px;
    height: 20px;
  }
  p{
    margin-top: 5px;
  }
  @include media-breakpoint-up(lg){
    span {
      font-size: 30px; 
    }
    p{
      margin-top: 15px;
    }
  }
}
.payment-methods.active,
.payment-methods:hover{
  border: 2px solid $fundo-button-color;
}

.payment-methods::before {
  position: absolute;
  content: "";
  left: -3px;
  top: 28px;
  bottom: -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-color: $input-border-color;
  border-style: solid;
  background: $white;
  @include media-breakpoint-up(lg) {
    top: 26px;
    height: 15px;
    width: 15px;
    left: 9px;
  }
}
.payment-methods.active::before,
.payment-methods:hover::before {
  background: $fundo-button-color;
  border-width: 1px;
  box-shadow: inset 0 0 0 2px #fff;
  @include media-breakpoint-up(lg){
    box-shadow: inset 0 0 0 3px #fff;
  }
}

.description-payment{
  margin: 5px;
  font-size: 14px;
}

.brands-accepted{
  text-align: center;
  font-size: 16px;
  span {
    margin-top: 0;
  }
}

.payment-data {
  border-top: 1px solid $gray-300;
}

.input-default {
  border-radius: 4px;
  font-size: 14px;
  line-height: 30px;
  padding: 10px;
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 390px;
  }
}

#sou {
  position: relative;
  top: 9px;
}

.radio {
  width: 10px;
  height: 10px;
  font-size: 12px;
}

#label-pf {
  position: relative;
  bottom: 16px;
}

#label-pj {
  position: relative;
  bottom: 16px;
}


::-webkit-input-placeholder {
  color: $gray-500;
}

:-moz-placeholder { /* Firefox 18- */
  color: $gray-500;  
}

::-moz-placeholder {  /* Firefox 19+ */
  color: $gray-500;  
}

:-ms-input-placeholder {  
  color: $gray-500;  
}

.input-default.medio {
  color: $label-color;
  outline: none;
  @include media-breakpoint-up(lg){
    width: 225px;
  }
}

.input-default.cep {
  width: 150px;
}

.input-default.payment-max {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}

.input-default.payment-med {
  height: 30px;
  padding-top: 0px;
  color: $text-color;
  outline: none; 
  @include media-breakpoint-up(lg) {
    width: 240px;
  }
}



.label-container {
  position: absolute;
  top: 27px;
  right: 20px;
  
  @include media-breakpoint-up(lg){
    left: 290px;
  }
  
}

.texto-formulario {
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.consulta{
  font-size: 11px;
}

.input-default.short {
  width: 64px;
  font-size: 14px;
  color: $text-color;
  outline: none;
  margin: 2px;
}

.expiration {
  width: 190px;
}

.input-default.short.cvv {
  width: 100px;
}

.input-default.short.cvv::placeholder{
  font-size: 12px;
}

.input-default.min {
  @include media-breakpoint-up(lg){
    width: 100px;
  }
}

.img-duvida{
  position: relative;
  right: 25px;
  top: 4px;
}

.data-sale {
  width: 150px;
  text-align: start;
}

.items-sale {
  padding: 0;
  animation-name: fadeInDown;
  animation-duration: 0.8s;
}

.price-sale {
  padding: 0;
  width: 150px;
  text-align: end;
  font-weight: 500;
}

.policies {
  text-align: center;
  @include media-breakpoint-up(lg){

  }
  input {
    width: 15px;
    height: 15px;
  }
}


#btn-payment {
  width: 100%;
}

.payment-identifier {
  border: 1px solid $border-menu-identificador;
}

.form-data{
  box-shadow: $box-shadow-form-data;
  border: $border-form-data;
  padding-top: 10px;
  padding-bottom: 5px;
  @include media-breakpoint-up(lg){
    padding: 12px;
  }
}

.icons{
  width: 30px;
  height: 25px;
  @include media-breakpoint-up(lg){
    height: 30px;
  }
}

.box-payment {
  border-bottom: 1px solid $gray-300;
  height: 60px;
  span {
    font-size: 15px;
  }
  p {
    font-size: 12px;
  }
}

.img.shield {
  position: relative;
  top: 15px;
  left: 10px;
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.fundo {
  position: relative;
  bottom: 5px;
  left: 35px;
  color: $fundo-button-color;
  font-size: 12px;
  @include media-breakpoint-up(lg) {
    background-color: $fundo-button-color;
    color: $white;
    font-size: 12px;
    text-align: start;
    width: 190px;
    height: 30px;
    position: absolute;
    margin-top: 12px;
    top: 2px;
    left: -22px;
  } 
}

img.img-shield {
  color: $input-border-color;
  @include media-breakpoint-up(lg){
    position: relative;
    top: 5px;
    left: 15px;
  }
}

span.texto-seguro {
  font-size: 12px;
  @include media-breakpoint-up(lg){
    margin-left: 25px;
  }
}

.fundo::before {
  @include media-breakpoint-up(lg){
    position: absolute;
    content: "";
    left: -12px;
    bottom: -5px;
    height: 0;
    width: 20px;
    border-width: 0 7px 5px 0;
    border-color: $border-color-payment-security;
    border-style: solid;
  }
}

.pais {
  text-align: end;
  position: relative;
  top: -17px;

}

.img.brand {
  position: relative;
  top: 18px;
  left: 46px;
  height: 13px;
  width: 20px;
}

.language {
  position: relative;
  top: 16px;
  font-size: 12px;
  outline: none;
  border: none;
  left: 50px;
  @include media-breakpoint-up(lg){
    width: 50%; 
  }
}

.select-language {
  position: relative;
  bottom: 5px;
}

.box-pay {
  position: relative;
  top: 4px;
  @include media-breakpoint-up(lg){
    top: 0px;
  }
}

.btn-sale {
  background-color: $white;
  border: 1px solid $color-button-reserva;
  border-radius: 4px;
  box-shadow: none;
  color: $color-button-reserva;
  cursor: pointer;
  font-size: 11px;
  height: 20px;
  outline: none;
  padding: 3px;
  width: 70px;
}

.btn-sale:active {
  background-color: $color-button-reserva;
  color: $white;
}

#subtotal::before {
  @include media-breakpoint-up(lg){
    content: '';
    display: inline-block;
    width: 350px;
    height: 1px;
    background: $border-menu-identificador;
    margin-left: 18px; 
  }
}

.total {
  background-color: $background-total;
}

.rodape-pagamento {
  display: none;
  align-items: center;
  max-width: 300px;
}

.rodape {
  border-top: 1px solid $border-menu;
  font-size: 12px;
}

//Validador dos errors

.error{
  font-size: 12px;
  color: $color-error;
  margin-top: 2px;
  margin-left: 2px; 
}

.error-brand {
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.input-default.payment-max.error,
.input-default.short.error,
.input-default.short.cvv.error,
.input-default.payment-med.error
 {

  border: $border-error;
}

.input-default.error,
.input-default.medio.error,
.input-default.cpf.error
{
  border: $border-error;
}

.select-medio {
  padding: 6px;
}

.payment-text {
  background-color: $fundo-button-color;
  color: $white;
  display: flexbox;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.icon-input-card{
  width: 30px;
  height: 25px;
  position: absolute;
  right: 35px;
  top: 27px;
}

.icon-input-code{
  width: 30px;
  position: relative;
  top: 7px;
  left: 5px;
}

.brands {
  border-radius: 10%;
  width: 50px;
  height: 40px;
  padding: 2px 5px;
  @include media-breakpoint-up(lg) {
    margin: 0px 5px;
  }
  margin: 0px 1px;
}

.title-brands{
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.brands-radio:hover{
  outline: none;
}

.brands-radio{
  cursor: pointer;
  outline: none;
}

.brands-radio::after{
  display: block;
  position: absolute;
  content: "";
  margin-left: 21px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border-width: 1px;
  border-color: $input-border-color;
  border-style: solid;
  margin-top: -5px;
  background:$white;
  @include media-breakpoint-up(lg){
    margin-left: 25px;
  }
}

.brands-radio.active::after,
.brands-radio:hover::after{
  background: $fundo-button-color;
  border-width: 1px;
  box-shadow: inset 0 0 0 2px #fff;
}

.msg-pgto-confirmado {
  background-color: $fundo-button-color;
  color: $white;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
}

.not-found {
  height: 80vh;
  text-align: center;
}

.waiting{
  min-height: 100vh;
  align-items: center;
  text-align: center;
}

.title-confirmado {
  color: $title-confirmed;
  text-align: center;
  font-size: 30px;  
}

.arrow-button{
  position: relative;
  bottom: 2px;
  left: 2px;
}

#arrow {
  font-size: 15px;
  cursor: pointer;
}

.back {
  cursor: pointer;
  font-size: 14px;
  padding-left: 2px;
}

.loading {
  display: flex;
  text-align: center;
  margin-top: 50px;
}

.finalizado {
  text-align: center;
  font-size: 20px;
}

.content-alert {
  overflow-y: auto;
  font-size: 15px;
  -webkit-overflow-scrolling: scroll;
  @include media-breakpoint-up(lg){
    font-size: 18px;
  }
}

.modal{
  display: block;
}

.policy-text {
  p, span, div, li, ol, td {
    font-size: 15px !important;
  }
  h1 {
    font-size: 22px !important;
  } 
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  h4, h5, h6 {
    font-size: 16px !important;
  }
  -webkit-overflow-scrolling: touch; 
  max-height: 250px;
  text-align: left;
}

.close-button{
  outline: none;
  &:focus,
  &:active{
    box-shadow: none;
  }
}

.button-cookie{
  outline: none;
  position: relative;
  top: 10px;
  display: flex;
  z-index: 0;
}

.alert-cookie{
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 32rem;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.show-alert{
  width: 100%;
  animation-name: fadeInDown;
  animation-duration: 2s;
  text-align: center;
  align-items: center;
  opacity: 95%;
  background-color: $fundo-button-color;
  color: $white;
  z-index: 999999;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.form-pgto-error{
  min-height: 300px;
  font-size: 18px;
  text-align: center;
}

.mostra-dados-cep{
  animation-name: fadeInDown;
  animation-duration: 2s;
}

.check-term {
  min-height: 15px;
}

.confirmed {
  color: $fundo-button-color;
  font-size: 93px;
}

.value-pay{
  font-size: 30px;
  font-weight: 600;
}

.copy-pix{
  border: 1px solid $border-menu-identificador;
  height: 100%;
  margin-top: 10%;
  text-align: center;
  width: 100%;
}

.title-pix{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
}

.img-pix{
  width: 100px;
}

.value-pay-pix{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.container-pix {
  text-align: center;
}

.value-discount{
  align-items: flex-start;
}

.message-expire-pix{
  font-weight: 600;
  color: #ff2b2b;
}

#timer {
  position: relative;
  bottom: 3px;
}

#copy-pix{
  position: relative;
  bottom: 2px;
  font-size: 20px;
}

.discount {
  margin: 2%;
  width: 100%;
}

.old{
  text-decoration: line-through;
}

.new{
  font-weight: 900;
}

.percentage{
  border: $border-percentage-pix;
  border-radius: 50%;
  margin-left: 5%;
  padding: 10px;
}

.value-total {
  flex: 2;
}

.finalize-pix{
  background-color: $background-topo;
  box-shadow: $box-shadow-pix;
  height: 100%;
}

.title-type-pix {
  margin: 0;
}

.alert-warning{
  color: rgba(229, 255, 0, 0.952);
}

.copy-code {
  cursor: pointer;
}